body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(205, 184, 90);
}

h1 {
  display: inline;
  font-size: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#header {
  position: fixed;
  background-color: black;
  height: 90px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 100;
}

#header-logo-parent {
  margin-left: 20px;
  position: fixed;
  top: 15px;
  left: 10px;
}

#header-logo {
  width: 60px;
  height: 60px;
}

#header-text {
  display: inline;
  position: relative;
  top: -21px;
  left: 20px;
  font-size: 24px;
  color: white;
}

#routes {
  display: flex;
}

#routes>div>a {
  margin: 15px;
  font-size: 20px;
  color: white;
  text-decoration: none;
}

.page-content {
  padding-top: 90px;
  width: 100vw;
  height: 100%;
}

.green-cover {
  height: calc(100vh - 90px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(205, 184, 90);
  /* background-color: #c8b047e6; */
}

.cover-content,
.home-cover-content {
  width: 1300px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

#home-image-parent {
  display: flex;
  align-items: center;
}

#spotify-section {
  display: flex;
  align-items: center;
}

#home-image {
  width: 320px;
  height: 320px;
  border-radius: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#text-section {
  display: flex;
  align-items: center;
}

#black-box {
  background-color: black;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  padding: 20px;
  width: 500px;
}

#green-box {
  background-color: rgb(205, 184, 90);
  color: black;
  font-size: 20px;
  border-radius: 5px;
  padding: 20px;
  width: 500px;
}

#home-text > div {
  margin-bottom: 20px;
}

.black-cover {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

#spotify-top-tracks {
  height: 80%;
  width: 80%;
}

#home-more-content {
  width: 1300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#more-text * {
  margin-bottom: 30px;
}

#footer {
  background-color: black;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

#footer-content {
  width: 1300px;
  display: flex;
  justify-content: center;
}

#footer-content > div {
  width: 50%;
}

.footer-header {
  font-size: 32px;
  margin-bottom: 12px;
}

#email-link > a {
  text-decoration: none;
  color: rgb(205, 184, 90);
  font-size: 21px;
}

#footer-motto {
  font-size: 26px;
  text-align: center;
}

#footer-img-parent {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
}

#footer-logo {
  width: 120px;
  height: 120px;
}

.heading {
  font-size: 46px;
}

.look-lemons-heading {
  font-size: 32px;
}

.love-making-music-text {
  font-size: 22px;
}

.home-pitch {
  font-size: 18px;
  line-height: 1.4;
}
.track-text {
  font-size: 20px;
}

.about-text {
  font-size: 18px;
}

#menu-button {
  color: white;
  font-size: 24px;
  margin-right: 15px;
  display: none;
}

#mobile-menu {
  background-color: black;
  position: fixed;
  top: 90px;
  width: 100vw;
  height: calc(100vh - 90px);
  color: white;
}

#menu-list {
  padding: 20px;
  /* display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly; */
}

#menu-list > div {
  margin: 55px 0;
  font-size: 28px;
  text-align: center;
}

.contact-text {
  font-size: 28px;
  padding: 0 20px;
}

@media screen and (max-width: 1162px) {
  .green-cover,
  .black-cover {
    height: unset;
  }
  .home-cover-content {
    flex-direction: column;
    width: unset;
    height: 1000px;
    justify-content: space-evenly;
  }

  .cover-content{
    flex-direction: column;
    width: unset;
    height: 800px;
    justify-content: space-evenly;
  }

  .cover-content.about {
    height: 1200px;
  }

  #home-image-parent {
    justify-content: center;
  }

  #text-section {
    justify-content: center;
  }

  #spotify-section {
    justify-content: center;
  }

  #menu-button {
    display: block;
  }

  #routes {
    display: none;
  }

  .cover-content.contact {
    height: 100vh;
  }
}

@media screen and (max-width: 635px) {
  .green-cover.track-cover,
  .black-cover.track-cover {
    height: 800px;
  }

  #black-box,
  #green-box {
    width: 82%;
  }

  #footer-motto {
    font-size: 18px;
    line-height: 1.6;
  }

  .heading {
    font-size: 36px;
  }
  .look-lemons-heading {
    font-size: 26px;
  }
  .love-making-music-text {
    font-size: 18px;
  }
  .home-pitch {
    font-size: 16px;
    line-height: 1.5;
  }
  .track-text {
    font-size: 18px;
  } 

  .about-text {
    font-size: 16px;
  }

  #routes {
    display: none;
  }

  #header-logo-parent {
    margin-left: 7px;
  }

  .contact-text {
    font-size: 26px;
  }

  #email-link > a {
    font-size: 19px;
    word-break: break-all;
  }
}